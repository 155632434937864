.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.webviewer {
  height: 90vh;
  width: 100%;
}
