.barcodeContainer {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.barcodeCanvas {
  width: 300px;
  height: 175px;
}

.qrCanvas {
  float: right;
  width: 50px;
  height: 50px;
}

h2 {
  margin-bottom: 0px;
}

input,
select,
button,
text {
  margin: 8px 0px;
  height: 30px;
}

.error {
  color: red;
}
